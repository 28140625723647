

import ComponentLoader from '/root/project/node_modules/@uplight/marketplace-components/lib/ComponentLoader';
import Maintenance from '/root/project/node_modules/@uplight/marketplace-components/src/maintenance/Maintenance';


const componentList = [
  Maintenance,
];

new ComponentLoader(componentList).load();
